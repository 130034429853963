import { defineStore } from "pinia";
import { OrderService } from "@/services/OrderService";
import { OrderDetailModel, OrderFinishModel, OrderProductCreateModel } from "@/types/Order";
import { useShopHub } from "@/services/ShopHub";
import { useLocalSettings } from "@/services/LocalSettings";

export interface StaffDisplayOrder extends OrderDetailModel {
    remainingPercentage: number;
}

function toStaffDisplayObj(order: OrderDetailModel): StaffDisplayOrder {
    const temp: StaffDisplayOrder = {
        remainingPercentage: 1,
        ...order
    };
    return temp;
}

const localSettings = useLocalSettings();

function getPercentage(finishTime: string):number{
    const ft = new Date(finishTime);
    const ftNow = new Date();
    const diff = ftNow.valueOf() - ft.valueOf();
    const seconds = diff/1000;
    return 1-(seconds / localSettings.StaffFinishedOrderShowTime);
}
const os = new OrderService();
const sh = useShopHub();

export const useStaffStore = defineStore({
    id: "staffStore",
    state: () => ({
        orders: [] as StaffDisplayOrder[],
        isWorking: false,
        finishCounterTimerId: 0,
        finishedOrders: [] as OrderDetailModel[],
        finishedOrdersPage: 1,
        finishedOrdersTotalCount: 0,
        finishedOrdersPageSize: 12,
        isFinishedOrdersPageSelected: false
    }),
    actions: {
        getById(id: number):StaffDisplayOrder | null {
            const idx = this.orders.findIndex(function (i) {
                return i.id === id;
            });
            if(idx==-1)
                return null;
            return this.orders[idx];
        },
        async start(){
            if(this.isWorking)
                return;
            this.isWorking = true;
            this.finishCounterTimerId = setInterval(()=>{
                const idsToRemove:number[] = [];
                this.orders.forEach(order => {
                    if(order.isFinished){
                        order.remainingPercentage = getPercentage(order.finishTime);
                        if(order.remainingPercentage <= 0){
                            idsToRemove.push(order.id);
                        }
                    }
                });
                idsToRemove.forEach(id => {
                    this.orders.splice(this.orders.findIndex(function(i){
                        return i.id === id;
                    }), 1);
                });
            },250);
            sh.OrderCreated.on(this.orderCreatedEvent);
            sh.OrderFinished.on(this.orderFinishedEvent);
            sh.Established.on(this.establishedEvent);
            this.getOrders();
        },
        async stop(){
            if(!this.isWorking)
                return;
            this.isWorking = false;
            clearInterval(this.finishCounterTimerId);
            sh.OrderCreated.off(this.orderCreatedEvent);
            sh.OrderFinished.off(this.orderFinishedEvent);
            sh.Established.off(this.establishedEvent);
        },
        async getOrders(){
            try {
                this.orders = (await os.getStaffOrders()).map(o=>toStaffDisplayObj(o));
            } catch (error) {
                this.orders = [];
            }
        },
        async completeOrder(id: number){
            try {
                await os.completeOrder(id);
            } catch (error) {
                console.log(error);
            }
        },
        orderCreatedEvent(order: OrderDetailModel){
            const existingOrder = this.getById(order.id);
            if(existingOrder==null){
                this.orders.push(toStaffDisplayObj(order));
            }else{
                console.log("this shouldnt happen though");
            }
        },
        orderFinishedEvent(order: OrderFinishModel){
            const existingOrder = this.getById(order.id);
            if(existingOrder!=null){
                existingOrder.isFinished = true;
                existingOrder.finishTime = order.finishTime;
            }
        },
        establishedEvent(){
            console.log("established the hub, regeting orders");
            this.getOrders();
        },
        async loadFinishedOrders(page: number){
            if(page<1){
                console.log("page cannot be less than 1!!");
                return;
            }
            try {
                const fRes = await os.getFinishedOrders(page,this.finishedOrdersPageSize);
                this.finishedOrders = fRes.orders;
                this.finishedOrdersTotalCount = fRes.orderCount;
                this.finishedOrdersPage = fRes.page;
                this.isFinishedOrdersPageSelected = true;
            } catch (error) {
                this.isFinishedOrdersPageSelected = false;
            }

        }
    },
    getters: {
        unfinishedOrdersCount():number{
            return this.orders.filter(p => !p.isFinished).length;
        }
        // filteredProducts() : CartProduct[]{
        //     return this.products.filter(product => product.category === this.category);
        // },
    }
});

// export type StaffStore = typeof useStaffStore;