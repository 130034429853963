import { defineStore } from "pinia";
import { OrderService } from "@/services/OrderService";
import { OrderFinishModel, OrderListModel } from "@/types/Order";
import { useShopHub } from "@/services/ShopHub";
import { useLocalSettings } from "@/services/LocalSettings";


const localSettings = useLocalSettings();

function isShowTimeOver(time: string):boolean{
    const ft = new Date(time);
    const ftNow = new Date();
    const diff = ftNow.valueOf() - ft.valueOf();
    const seconds = diff/1000;
    return seconds > localSettings.DisplayFinishedOrderShowTime;
}

const os = new OrderService();
const sh = useShopHub();

export const useDisplayStore = defineStore({
    id: "displayStore",
    state: () => ({
        orders: [] as OrderListModel[],
        isWorking: false,
        finishCounterTimerId: 0
    }),
    actions: {
        getIndex(id: number){
            const idx = this.orders.findIndex(function (i) {
                return i.id === id;
            });
            return idx;
        },
        getById(id: number):OrderListModel | null {
            const idx = this.orders.findIndex(function (i) {
                return i.id === id;
            });
            if(idx==-1)
                return null;
            return this.orders[idx];
        },
        async start(){
            if(this.isWorking)
                return;
            this.isWorking = true;
            this.finishCounterTimerId = setInterval(()=>{
                const idsToRemove:number[] = [];
                this.orders.forEach((order) => {
                    if(order.isFinished){
                        if(isShowTimeOver(order.finishTime)){
                            console.log("preparing "+order.id+" to remove from orders");
                            idsToRemove.push(order.id);
                        }
                    }
                });
                idsToRemove.forEach(id => {
                    console.log("removing "+id+" from orders");
                    this.orders.splice(this.orders.findIndex(function(i){
                        return i.id === id;
                    }), 1);
                });
            },250);
            sh.OrderCreatedSimple.on(this.orderCreatedEvent);
            sh.OrderFinished.on(this.orderFinishedEvent);
            sh.Established.on(this.establishedEvent);
            this.getOrders();
        },
        async stop(){
            if(!this.isWorking)
                return;
            this.isWorking = false;
            clearInterval(this.finishCounterTimerId);
            sh.OrderCreatedSimple.off(this.orderCreatedEvent);
            sh.OrderFinished.off(this.orderFinishedEvent);
            sh.Established.off(this.establishedEvent);
        },
        async getOrders() {
            try {
                this.orders = await os.getDisplayOrders();
            } catch (error) {
                this.orders=[];
            }
        },
        orderCreatedEvent(order: OrderListModel){
            const existingOrder = this.getById(order.id);
            if(existingOrder==null){
                this.orders.push(order);
            }else{
                console.log("this shouldnt happen though");
            }
        },
        orderFinishedEvent(order: OrderFinishModel){
            const existingOrder = this.getById(order.id);
            console.log("order finish");
            if(existingOrder!=null){
                existingOrder.isFinished = true;
                existingOrder.finishTime = order.finishTime;
            }
        },
        establishedEvent(){
            console.log("established the hub, regeting orders");
            this.getOrders();
        }
    },
    getters: {
        finishedOrders():OrderListModel[]{
            return this.orders.filter(order => order.isFinished);
        },
        unfinishedOrders():OrderListModel[]{
            return this.orders.filter(order => !order.isFinished);
        }
        // filteredProducts(): CartProduct[] {
        //     return this.products.filter(product => product.category === this.category);
        // },
    }
});