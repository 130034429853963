import { createWebHistory, createRouter, Router, RouteLocationNormalized } from "vue-router";
import { nextTick } from 'vue';
import Manage from "@/views/Manage.vue";
import Purchase from "@/views/Purchase.vue";
import Staff from "@/views/Staff.vue"
import Display from "@/views/Display.vue"
import LocalSettings from "@/views/LocalSettings.vue"
import FinishedOrders from "@/views/FinishedOrders.vue"
import { useCartStore } from "@/store/CartStore";
import Stats from "@/views/Stats.vue"

interface RouterWithMemory extends Router {
  previousRoute: RouteLocationNormalized;
}

const routes = [
  {
    path: "/manage",
    name: "Správa",
    component: Manage,
  },
  {
    path: "/purchase",
    name: "Občerstvení",
    component: Purchase,
  },
  {
    path: "/staff-order",
    name: "Obsluha",
    component: Purchase,
  },
  {
    path: "/staff",
    name: "Seznam objednávek",
    component: Staff,
  },
  {
    path: "/display",
    name: "Vyřízeno",
    component: Display,
  },
  {
    path: "/staff-finished",
    name: "Dokončené objednávky",
    component: FinishedOrders,
  },
  {
    path: "/localsettings",
    name: "Lokální nastavení",
    component: LocalSettings,
  },
  {
    path: "/stats",
    name: "Statistiky",
    component: Stats,
  }
];
//TODO:
// /        Rozcestník
// /manage  Mangment
// /purchase Výběr kategorie + výběr produktu do košíku
// /cart    Košík
// /staff   Displej pro obsluhu s potvrzováním objednávek
// /display Displej pro lidi s ukázkami posledních objednávek

const router = createRouter({
  history: createWebHistory(),
  routes,
}) as RouterWithMemory;

function hasQueryParams(route: any) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  router.previousRoute = from;
  if (to.path == "/") {
    next({ path: "/purchase" });
  }

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ path: to.path, query: from.query });
  } else {
    next()
  }
})

const DEFAULT_TITLE = 'Objednání jídla';


router.afterEach((to, from) => {
  const cartStore = useCartStore();
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    let name = to?.name?.toString();
    if (name != null) {
      if (to.path == "/purchase") {
        if (cartStore.newOrderProcessBegun) {
          name = "Vytváření objednávky";
        }
      }
      document.title = name + " · " + DEFAULT_TITLE;
    }
    else {
      document.title = DEFAULT_TITLE;
    }
  });
});

export default router;
