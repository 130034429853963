

class LocalSettings{
    private staffFinishedOrderShowTime: number = 30;
    private displayFinishedOrderShowTime: number = 120;
    
    public get StaffFinishedOrderShowTime(): number {
        return this.staffFinishedOrderShowTime;
    }
    public get DisplayFinishedOrderShowTime(): number {
        return this.displayFinishedOrderShowTime;
    }

    load(){
        console.log("Loading LocalSettings");
        const staffFinTime = localStorage.getItem("staffFinishedOrderShowTime");
        if(staffFinTime!=null){
            this.staffFinishedOrderShowTime = Number(staffFinTime);
        }
        const displayFinTime =  localStorage.getItem("displayFinishedOrderShowTime");
        if(displayFinTime!=null){
            this.displayFinishedOrderShowTime = Number(displayFinTime);
        }
    }
    save(staffShowTime: number, displayShowTime: number){
        this.staffFinishedOrderShowTime = staffShowTime;
        this.displayFinishedOrderShowTime = displayShowTime;
        localStorage.setItem("staffFinishedOrderShowTime",this.staffFinishedOrderShowTime.toString());
        localStorage.setItem("displayFinishedOrderShowTime",this.displayFinishedOrderShowTime.toString());
    }
}

const instance = new LocalSettings();
export const useLocalSettings = ()=>{
    return instance;
};