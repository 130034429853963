import { defineStore } from "pinia";
import { Product } from "@/types/Product";
import { ProductService } from "@/services/ProductService";
import { ProductCategory } from "@/types/ProductCategory";
import { OrderService } from "@/services/OrderService";
import { OrderProductCreateModel } from "@/types/Order";
import { currentCategory, isCategorySelected } from "@/services/SubNavigation";
import { useShopHub } from "@/services/ShopHub";

export interface CartProduct extends Product {
    amount: number;
    historyTag: number;
    imageUrl: string;
}

function toManageObj(product: Product): CartProduct {
    const temp: CartProduct = {
        amount: 0,
        historyTag: 0,
        ...product,
        imageUrl: ProductService.getImageUrl(product)
    };
    return temp;
}

const ps = new ProductService();
const os = new OrderService();
const sh = useShopHub();

export const useCartStore = defineStore({
    id: "cartStore",
    state: () => ({
        products: [] as CartProduct[],
        orderNote: "" as string,
        isWorking: false
    }),
    actions: {
        getById(id: number){
            const idx = this.products.findIndex(function (i) {
                return i.id === id;
            });
            return this.products[idx];
        },
        getByIdUnsafe(id: number): CartProduct | null{
            const idx = this.products.findIndex(function (i) {
                return i.id === id;
            });
            if(idx==-1)
                return null;
            return this.products[idx];
        },
        async start(){
            if(this.isWorking)
                return;
            this.isWorking = true;
            sh.ProductUpdated.on(this.productUpdatedEvent);
            sh.Established.on(this.refetch);
            this.refetch();
        },
        async stop(){
            sh.ProductUpdated.off(this.productUpdatedEvent);
            sh.Established.off(this.refetch);
        },
        async refetch(){
            console.log("refetching products");
            const prods = await ps.getAll();
            prods.forEach(product => {
                this.productUpdatedEvent(product);
            });
        },
        async productUpdatedEvent(product: Product){
            const existing = this.getByIdUnsafe(product.id);
            if(existing==null){
                this.products.push(toManageObj(product));
            }else{
                existing.name = product.name;
                existing.price = product.price;
                existing.category = product.category;
                existing.isAvailable = product.isAvailable;
                if(product.isAvailable==false){
                    existing.amount = 0;
                }
            }
        },
        increaseAmount(id: number) {
            const product = this.getById(id);
            product.amount++;
        },
        decreaseAmount(id: number) {
            const product = this.getById(id);
            if (product.amount > 0) {
                product.amount--;
            }
        },
        resetOrder() {
            this.products.forEach(product => {
                product.amount = 0;
                product.historyTag = 0;
            });
            this.orderNote = "";
        },
        async placeOrder(): Promise<number> {
            let orderProducts: OrderProductCreateModel[] | null = null;
            orderProducts = this.cartContent.map(product => {
                return { productId: product.id, amount: product.amount };
            });
            const orderId = await os.create({note: this.orderNote, products: orderProducts});
            this.resetOrder();
            return orderId;
        }
    },
    getters: {
        filteredProducts(): CartProduct[] {
            return this.products.filter(product => product.category === currentCategory.value);
        },
        cartContent(): CartProduct[] {
            const tt = new Date().getTime();
            this.products.forEach(product => {
                if (product.amount != 0) {
                    if (product.historyTag == 0) {
                        product.historyTag = tt;
                    }
                }
                else {
                    if (product.historyTag != 0) {
                        product.historyTag = 0;
                    }
                }
            });
            return this.products.filter(product => product.amount > 0).sort((a, b) => a.historyTag - b.historyTag);
        },
        cartTotal(): number {
            let total = 0;
            //TODO: fixed number arithmetics
            this.products.forEach(product => {
                total += product.price * product.amount;
            });
            return total;
        },
        newOrderProcessBegun(): boolean {
            return isCategorySelected.value || this.products.findIndex(p => p.amount > 0) != -1
        },
        canOrder():boolean{
            return this.cartContent.length>0;
        }
    }
});