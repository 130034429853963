import { Product } from "@/types/Product";
import http from "./AxiosClient"

export class ProductService{
    async getAll():Promise<Product[]> {
        const results = await http.get("Product");
        const objects:Product[] = results.data;
        return objects;
    }
    async create(newProduct: Product):Promise<number> {
        const results = await http.post("Product",newProduct);
        return results.data;
    }
    async update(product: Product):Promise<boolean> {
        const results = await http.put("Product",product);
        //TODO: react to errors
        return true;
    }
    async delete(id: number):Promise<void> {
        const results = await http.delete(`Product/${ id }`);
        return results.data;
    }
    async uploadImage(id: number, imageFile: File):Promise<void>{
        const formData = new FormData();
        formData.append("image", imageFile);
        const results = await http.post(`Product/SetImage/${ id }`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        });
    }
    static getImageUrl(product: Product) : string {
        return `${http.defaults.baseURL}/ProductImg/product_${product.id}.png`;
    }
}