export class PrintService {
    async printOrder(orderNum: number): Promise<void> {
        return new Promise((resolve, reject) => {
            const ua = navigator.userAgent.toLowerCase();
            const isAndroid = ua.indexOf("android") > -1;

            const canvas = document.createElement('canvas')
            canvas.height = 80
            canvas.width = 200
            const context = canvas.getContext('2d')
            if (context != null) {
                context.fillStyle = 'white';
                context.fillRect(0, 0, 200, 100);
                context.fillStyle = 'black'
                context.font = "50px system-ui"
                context.textAlign = "center"
                context.textBaseline = "middle"
                context.fillText(orderNum.toString(), 100, 20);

                context.lineWidth = 1;
                context.moveTo(0,79);
                context.lineTo(199,79);
                context.stroke();
                const dataUrl = canvas.toDataURL("image/jpeg");
                if (isAndroid) {
                    // Execute RawBT print intent
                    printBase64(dataUrl);
                    resolve();
                }
                else {
                    debugBase64(dataUrl);
                    resolve();
                }
            }

        })
    }
}

/**
 * Display a base64 URL inside an iframe in another window.
 */
function debugBase64(base64URL: string) {
    const win = window.open();
    win?.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    win?.print();
}

function printBase64(base64URL: string) {
    const iframe = document.createElement('iframe');
    iframe.src = 'rawbt:' + base64URL;
    iframe.frameBorder = '0';
    iframe.width = '0px';
    iframe.height = '0px';
    document.body.appendChild(iframe);
    setTimeout(() => iframe.remove(), 2000);
}