export enum ProductCategory {
    Food = "Food",
    Drink = "Drink",
    Other = "Other"
}

export const categoriesTranslation = [
    { val: ProductCategory.Food, text: "Jídlo", image: "/images/food.png" },
    { val: ProductCategory.Drink, text: "Pití", image: '/images/drink.png' },
    { val: ProductCategory.Other, text: "Drobnosti", image: '/images/sweets.png' }
];