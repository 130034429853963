import { OrderDetailModel, OrderFinishModel, OrderListModel } from "@/types/Order";
import { Product } from "@/types/Product";
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, IRetryPolicy, LogLevel } from "@microsoft/signalr"

interface ILiteEvent<T> {
    on(handler: { (data: T): void }) : void;
    off(handler: { (data: T): void }) : void;
}
type eventSubsChangedHandler = { (hasAnySubscriber: boolean): void };

class MonitoredEvent<T> implements ILiteEvent<T> {
    private handlers: { (data: T): void; }[] = [];
    private hasSubsChangedHandler: eventSubsChangedHandler | null;
 
    public get hasSubs() : boolean {
        return this.handlers.length > 0;
    }
    

    public on(handler: { (data: T): void }) : void {
        const hasSubsBeforePush = this.hasSubs;
        this.handlers.push(handler);
        if(hasSubsBeforePush==false){
            this.hasSubsChangedHandler?.(true);
        }
    }

    public off(handler: { (data: T): void }) : void {
        const hasSubsBeforeFilter = this.hasSubs;
        this.handlers = this.handlers.filter(h => h !== handler);
        if(hasSubsBeforeFilter && !this.hasSubs){
            this.hasSubsChangedHandler?.(true);
        }
    }

    public trigger(data: T) {
        this.handlers.slice(0).forEach(h => h(data));
    }

    public expose() : ILiteEvent<T> {
        return this;
    }

    constructor(subsHandler: eventSubsChangedHandler|null = null){
        this.hasSubsChangedHandler = subsHandler;
    }
}


type onOpenHandler = { (): void };

export class ShopHub {
    private connection: HubConnection;

    private groups: string[] = [];
    private readonly onProductUpdated;
    public get ProductUpdated() { return this.onProductUpdated.expose(); }

    private readonly onOrderCreated;
    public get OrderCreated() { return this.onOrderCreated.expose(); }

    private readonly onOrderCreatedSimple;
    public get OrderCreatedSimple() { return this.onOrderCreatedSimple.expose(); }

    private readonly onOrderFinished;
    public get OrderFinished() { return this.onOrderFinished.expose(); }

    private readonly onEstablished = new MonitoredEvent<void>();
    public get Established() { return this.onEstablished.expose(); }

    private readonly onDisconnected = new MonitoredEvent<void>();
    public get Disconnected() { return this.onDisconnected.expose(); }

    public get IsConnected() {return this.connection.state == HubConnectionState.Connected}
    // private useGroup(name: string, isUsed: boolean){
    //     if(isUsed){

    //     }
    // }

    constructor() {
        console.log("constructing weboscket");
        const retryPolicy: IRetryPolicy = {nextRetryDelayInMilliseconds:ctx=>{
            if(ctx.previousRetryCount<1)
                return 0;
            else if(ctx.previousRetryCount < 3)
                return 4000;
            else return 8000;
        }};
        this.connection = new HubConnectionBuilder()
            .withUrl(((process.env.VUE_APP_API_BASE || "http://192.168.19.142:32771") + "/ShopHub"), { skipNegotiation: true, transport: HttpTransportType.WebSockets })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect(retryPolicy)
            .build();

        this.connection.onreconnected((id) => {
            console.log("recconnected the client");
            this.runOnOpen();
        });
        this.connection.onreconnecting(()=>{
            this.onDisconnected.trigger();
        });
        this.connection.onclose((error) => {
            this.onDisconnected.trigger();
        });
        this.connection.on("ProductUpdated", (product: Product) => {
            this.onProductUpdated.trigger(product);
        });
        this.connection.on("OrderCreated", (order) => {
            this.onOrderCreated.trigger(order);
        });
        this.connection.on("OrderCreatedSimple", (order) => {
            this.onOrderCreatedSimple.trigger(order);
        });
        this.connection.on("OrderFinished", (order) => {
            this.onOrderFinished.trigger(order);
        });

        this.onProductUpdated = new MonitoredEvent<Product>();
        this.onOrderCreated = new MonitoredEvent<OrderDetailModel>();
        this.onOrderCreatedSimple = new MonitoredEvent<OrderListModel>();
        this.onOrderFinished = new MonitoredEvent<OrderFinishModel>();
    }

    async connect() {
        if(this.connection.state == HubConnectionState.Disconnected){
            await this.connection.start();
            console.log("concetd the client");
            this.runOnOpen();
        }

    }
    async disconnect() {
        this.connection.stop();
    }
    // onOpen(handler: onOpenHandler) {
    //     this.handlers.push(handler);
    // }
    private runOnOpen() {
        this.onEstablished.trigger();
    }
}
const shopHub = new ShopHub();
export const useShopHub = function (){
    console.log("using the function");
    return shopHub;
};