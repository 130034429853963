import globalRouter from '@/router/routes';
import { computed } from 'vue'
import { ProductCategory } from '@/types/ProductCategory';
import router from '@/router/routes';

export const currentCategory = computed<null | ProductCategory>(() => {
    return globalRouter?.currentRoute.value.query?.category == null ? null : (ProductCategory as any)[globalRouter.currentRoute.value.query.category as string];
})
export const cartPaneState = computed<number>(() => {
    const val = globalRouter?.currentRoute.value.query?.cartPaneState;
    let defaultState = 1;
    let stateFromRoute = parseInt(val as string);
    if (window.innerWidth < 600) {
        defaultState = 0;
        stateFromRoute = stateFromRoute == 2 ? 2 : 0;
    }
    return val == null ? defaultState : stateFromRoute;
})

export const isCategorySelected = computed<boolean>(() => currentCategory.value != null)

export function
    goToCategory(category: ProductCategory | null, base: null | string = null) {
    const current = globalRouter.currentRoute.value.path;
    globalRouter.push({
        path: current == "/" ? base ?? current : current,
        query: {
            category,
            cartPaneState: cartPaneState.value,
            btprint: globalRouter.currentRoute.value.query.btprint
        }
    })
}

export function goToCategorySelect(base: null | string) {
    if (router.previousRoute.path == router.currentRoute.value.path && router.previousRoute.query?.category == null) {
        router.back();
    }
    else {
        goToCategory(null, base);
    }
}

export const isCartFullScreen = computed<boolean>(() => cartPaneState.value == 2)

export function nextCartPaneState() {
    let nextState = (cartPaneState.value + 1) % 3;
    if (window.innerWidth < 600) {
        nextState = cartPaneState.value == 0 ? 2 : 0;
    }
    globalRouter.push({
        path: globalRouter.currentRoute.value.path,
        query: {
            category: currentCategory.value,
            cartPaneState: nextState,
            btprint: globalRouter.currentRoute.value.query.btprint
        }
    })
}

export const isBluetoothPrintMode = computed<boolean>(() =>
    globalRouter.currentRoute.value.query.btprint != null
);