import { OrderProductCreateModel, OrderDetailModel, OrderListModel, PaginatedOrderDetailModels, PlaceOrder } from "@/types/Order";
import http from "./AxiosClient"

export class OrderService{
    async getStaffOrders():Promise<OrderDetailModel[]> {
        const results = await http.get("Order/StaffList");
        const objects:OrderDetailModel[] = results.data;
        return objects;
    }
    async getDisplayOrders():Promise<OrderListModel[]>{
        const results = await http.get("Order/DisplayList");
        const objects:OrderDetailModel[] = results.data;
        return objects;
    }
    async create(order: PlaceOrder):Promise<number> {
        const results = await http.post("Order",order);
        return results.data;
    }
    async completeOrder(id: number):Promise<void>{
        const results = await http.patch(`Order/${ id }`);
        return results.data;
    }
    async getFinishedOrders(page: number, size: number):Promise<PaginatedOrderDetailModels>{
        const params = {
            Page: page,
            Size: size
        };
        const results = await http.get("Order/Finished",{params});
        const objects:PaginatedOrderDetailModels = results.data;
        return objects;
    }
    async getOrderDetail(id: number):Promise<OrderDetailModel>{
        const results = await http.get(`Order/${ id }`);
        return results.data;
    }
    async getOrderDisplayDetail(id: number):Promise<OrderListModel>{
        const results = await http.get(`Order/Display/${ id }`);
        return results.data;
    }
}