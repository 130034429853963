import { defineStore } from "pinia";
import { Product } from "@/types/Product";
import { ProductService } from "@/services/ProductService";
import { ProductCategory } from "@/types/ProductCategory";

export interface ProductManaged extends Product {
    isAvailableSwitchEnabled: boolean;
    imageUrl: string;
}

function toManageObj(product: Product):ProductManaged{
    const temp: ProductManaged = {
        isAvailableSwitchEnabled: true,
        ...product,
        imageUrl: ProductService.getImageUrl(product)
    };
    return temp;
}

const ps = new ProductService();

export const useProductStore = defineStore({
    id: "productStore",
    state: () => ({
        products: [] as ProductManaged[],
        category: ProductCategory.Food as ProductCategory,
        isInit: false
    }),
    actions: {
        getById(id: number){
            const idx = this.products.findIndex(function(i){
                return i.id === id;
            });
            return this.products[idx];
        },
        async getProducts(){
            if(!this.isInit){
                this.products = (await ps.getAll()).map(i=> toManageObj(i));
                // const ps = new ProductService();
            }
        },
        async createProduct(product: Product, imageFile: File | null){
            // const ps = new ProductService();
            const id:number = await ps.create(product);
            product.id = id;
            if(imageFile!=null){
                try {
                    await ps.uploadImage(id, imageFile);
                } catch (error) {
                    console.log(error);//TODO handle errors
                }
            }
            this.products.push(toManageObj(product));
        },
        async updateProduct(product: Product,imageFile: File | null){
            const xoxo = await ps.update(product);
            if(imageFile!=null){
                try {
                    await ps.uploadImage(product.id, imageFile);
                } catch (error) {
                    console.log(error);//TODO handle errors
                }
            }
            const idx = this.products.findIndex(function(i){
                return i.id === product.id;
            });
            this.products[idx] = toManageObj(product);
        },
        async deleteProduct(id: number){
            await ps.delete(id);

            this.products.splice(this.products.findIndex(function(i){
                return i.id === id;
            }), 1);
        },
        async toggleIsAvailable(id: number){
            const product = this.getById(id);
            const originalValue = !product.isAvailable;

            try {
                await this.updateProduct(product,null);
            } catch (error) {
                //TODO: api call
                product.isAvailable = originalValue;
            }

        }
    },
    getters: {
        filteredProducts() : Product[]{
            return this.products.filter(product => product.category === this.category);
        }
    }
});

export const useProductFormStore = defineStore({
    id: "productFormStore",
    state: ()=> ({
        isMakingNew: true as boolean,
        isBeingUsed: true as boolean,
        id: 0 as number,
        name: "" as string,
        price: 0 as number,
        category: ProductCategory.Food as ProductCategory,
        isAvailable: true as boolean,
        newImageFile: null as File | null
    }),
    actions: {
        reset(){
            this.id = 0;
            this.name = "";
            this.price = 0;
            this.category = ProductCategory.Food;
            this.isAvailable = true;
            this.newImageFile = null;
        },
        startNew(){
            this.reset();
            this.isMakingNew = true;
        },
        startModify(existingProduct: Product){
            this.reset();
            this.isMakingNew = false;
            this.id = existingProduct.id;
            this.name = existingProduct.name;
            this.price = existingProduct.price;
            this.category = existingProduct.category;
            this.isAvailable = existingProduct.isAvailable;
        },
        submit(){
            const theProduct: Product = {
                id: 0,
                name:  this.name,
                category: this.category,
                price: this.price,
                isAvailable: this.isAvailable
            };
            if(this.isMakingNew){
                const productStore = useProductStore();
                productStore.createProduct(theProduct, this.newImageFile);
                this.reset();
            }
            else{
                theProduct.id = this.id;
                const productStore = useProductStore();
                productStore.updateProduct(theProduct, this.newImageFile);
                this.reset();
                console.log("TODO: Update an object");
            }
        }
    }
});