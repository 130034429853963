import { createApp } from 'vue'
import { createPinia } from 'pinia'
// import the package
import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";



import App from './App.vue'
import router from './router/routes'

import 'bootstrap';
import '@/assets/globals.scss';

createApp(App).use(router).use(VueAwesomePaginate).use(createPinia()).mount('#app');

